import { WordMarkLogo, mergeClasses, theme } from '@expo/styleguide';
import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';
import { motion } from 'framer-motion';
import { useRouter } from 'next/compat/router';
import { useEffect } from 'react';

import { formatParams } from '~/common/formatParams';
import { useLocalStorage } from '~/providers/useLocalStorage';
import { getNextPageURL } from '~/scenes/Onboarding/helpers';
import { PageProps } from '~/scenes/_app/helpers';
import { Button } from '~/ui/components/Button';
import { H1 } from '~/ui/components/text';

const text = 'Apps for all of your users.';
const text2 = 'From you and your team.';

const color1 = 'rgba(255, 125, 150, 1)';
const color2 = 'rgba(220, 225, 100, 1)';
const color3 = 'rgba(0, 200, 255, 1)';

export function HelloScene({ currentUser }: Pick<PageProps, 'currentUser'>) {
  const router = useRouter();
  const { accountId } = formatParams(router?.query);

  const [, setOnboardingTimer] = useLocalStorage({
    name: 'onboarding-timer',
    defaultValue: '',
  });

  useEffect(function didMount() {
    setOnboardingTimer(new Date().toISOString());
  }, []);

  return (
    <div className="relative flex items-center justify-center overflow-hidden">
      <motion.div
        id="bg-wrap"
        className="absolute -inset-4 z-[-1] max-md-gutters:inset-x-[-33vw]"
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.1, duration: 1.5, ease: 'easeOut' }}>
        <svg viewBox="0 0 100 130">
          <defs>
            <radialGradient id="Gradient1" cx="50%" cy="50%" fx="0.441602%" fy="50%" r=".5">
              <animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite" />
              <stop offset="0%" stopColor={color1} />
              <stop offset="100%" stopColor={color1.replace('1)', '0)')} />
            </radialGradient>
            <radialGradient id="Gradient2" cx="50%" cy="50%" fx="2.68147%" fy="50%" r=".5">
              <animate attributeName="fx" dur="23.5s" values="0%;3%;0%" repeatCount="indefinite" />
              <stop offset="0%" stopColor={color2} />
              <stop offset="100%" stopColor={color2.replace('1)', '0)')} />
            </radialGradient>
            <radialGradient id="Gradient3" cx="50%" cy="50%" fx="0.836536%" fy="50%" r=".5">
              <animate attributeName="fx" dur="21.5s" values="0%;3%;0%" repeatCount="indefinite" />
              <stop offset="0%" stopColor={color3} />
              <stop offset="100%" stopColor={color3.replace('1)', '0)')} />
            </radialGradient>
          </defs>
          <rect
            x="13.744%"
            y="1.18473%"
            width="100%"
            height="100%"
            fill="url(#Gradient1)"
            transform="rotate(334.41 50 50)">
            <animate attributeName="x" dur="0.1s" values="25%;0%;25%" repeatCount="1" />
            <animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite" />
            <animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite" />
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 50 50"
              to="360 50 50"
              dur="10s"
              repeatCount="indefinite"
            />
          </rect>
          <rect
            x="-2.17916%"
            y="35.4267%"
            width="100%"
            height="100%"
            fill="url(#Gradient2)"
            transform="rotate(255.072 50 50)">
            <animate attributeName="x" dur="0.1s" values="-25%;0%;-25%" repeatCount="1" />
            <animate attributeName="x" dur="23s" values="-25%;0%;-25%" repeatCount="indefinite" />
            <animate attributeName="y" dur="24s" values="0%;50%;0%" repeatCount="indefinite" />
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 50 50"
              to="360 50 50"
              dur="17s"
              repeatCount="indefinite"
            />
          </rect>
          <rect
            x="9.00483%"
            y="14.5733%"
            width="100%"
            height="100%"
            fill="url(#Gradient3)"
            transform="rotate(139.903 50 50)">
            <animate attributeName="x" dur="0.1s" values="0%;25%;0%" repeatCount="1" />
            <animate attributeName="x" dur="25s" values="0%;25%;0%" repeatCount="indefinite" />
            <animate attributeName="y" dur="12s" values="0%;25%;0%" repeatCount="indefinite" />
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="360 50 50"
              to="0 50 50"
              dur="15s"
              repeatCount="indefinite"
            />
          </rect>
        </svg>
        <motion.svg
          viewBox="0 0 200 260"
          className="absolute inset-0"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          initial={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ delay: 1.5, duration: 1.5, ease: 'easeOut' }}>
          <rect width="200" height="260" fill="url(#viniette)" />
          <defs>
            <radialGradient
              id="viniette"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(100 130) scale(100)">
              <stop stopColor={theme.background.default} stopOpacity="0" />
              <stop offset="1" stopColor={theme.background.default} />
            </radialGradient>
          </defs>
        </motion.svg>
      </motion.div>
      <div className="relative flex size-full h-screen flex-1 flex-col items-center justify-center text-center">
        <motion.div
          className="mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            delay: 1,
            type: 'spring',
            stiffness: 100,
            damping: 20,
          }}>
          <WordMarkLogo className="h-[40px] w-[135px] text-default drop-shadow-sm" />
        </motion.div>
        <div>
          <H1
            weight="black"
            size="xl"
            className={mergeClasses(
              'flex cursor-default flex-wrap text-[6vw] drop-shadow-sm',
              'max-md-gutters:mb-4 max-md-gutters:justify-center',
              '[&>div]:select-none'
            )}>
            {text.split(' ').map((char, index) => (
              <motion.div
                key={char + '-' + index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  delay: 2 + index * 0.1,
                  type: 'spring',
                  stiffness: 100,
                  damping: 20,
                }}>
                {char}
                &nbsp;
              </motion.div>
            ))}
          </H1>
        </div>
        <div className="-mt-3 mb-12 max-md-gutters:mt-0">
          <H1
            weight="black"
            size="xl"
            className={mergeClasses(
              'flex cursor-default flex-wrap text-[6vw] drop-shadow-sm',
              'max-md-gutters:mb-4 max-md-gutters:justify-center',
              '[&>div]:select-none'
            )}>
            {text2.split(' ').map((char, index) => (
              <motion.div
                key={char + '-' + index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  delay: 3 + index * 0.1,
                  type: 'spring',
                  stiffness: 100,
                  damping: 20,
                }}>
                {char}
                &nbsp;
              </motion.div>
            ))}
          </H1>
        </div>
        <motion.div
          className="mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            delay: 4,
            type: 'spring',
            stiffness: 100,
            damping: 20,
          }}>
          <Button
            size="2xl"
            testID="onboarding-get-started-button"
            href={
              currentUser
                ? getNextPageURL('/onboarding/account/github', { accountId })
                : '/signup?is_onboarding=true'
            }
            className={mergeClasses(
              'rounded-lg border-2 border-palette-black bg-palette-black text-palette-white drop-shadow-sm',
              'hocus:bg-palette-white hocus:text-palette-black hocus:opacity-80'
            )}
            rightSlot={<ArrowRightIcon className="text-inherit" />}>
            Get Started
          </Button>
        </motion.div>
      </div>
    </div>
  );
}
