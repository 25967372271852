import { GetServerSidePropsContext } from 'next';

import { OnboardingEvents } from '~/common/analytics';
import { HelloScene } from '~/scenes/Onboarding/HelloScene';
import { useTrackOnboardingEvent } from '~/scenes/Onboarding/helpers';
import { PageProps, getServerSidePropsFor } from '~/scenes/_app/helpers';
import Document from '~/ui/components/Document';

function HelloPageInner({ currentUser }: PageProps) {
  useTrackOnboardingEvent(OnboardingEvents.ONBOARDING_HELLO_PAGE_VIEWED);

  return (
    <Document meta={{ name: 'onboarding', pageName: 'Hello' }} className="bg-transparent">
      <HelloScene currentUser={currentUser} />
    </Document>
  );
}

const HelloPage = HelloPageInner;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  return await getServerSidePropsFor(context, HelloPage);
}

export default HelloPage;
