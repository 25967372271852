import { useRouter } from 'next/compat/router';
import nullthrows from 'nullthrows';
import { useEffect, useRef } from 'react';

import { OnboardingEvents, track } from '~/common/analytics';
import { formatParams } from '~/common/formatParams';
import { categorizeAccounts } from '~/common/helpers';
import { usePageProps } from '~/providers/PagePropsContext';
import { LoggedInProps } from '~/scenes/_app/helpers';

export function getValidPlatformName(platform: string | null) {
  switch (platform) {
    case 'android': {
      return 'Android';
    }
    case 'ios': {
      return 'iOS';
    }
    default:
      return null;
  }
}

export function getNextPageURL(path: string, params?: Record<string, string | undefined>) {
  const filledOutParams = JSON.parse(JSON.stringify(params));
  const formattedParams = new URLSearchParams(filledOutParams).toString();
  return `${path}${filledOutParams && formattedParams.length ? '?' : ''}${formattedParams}`;
}

export function getPrimaryOnboardingAccount(
  currentUser: LoggedInProps['currentUser'],
  accountId?: string | null
) {
  const { personalAccount, organizationAccounts } = categorizeAccounts(currentUser);
  const organizationAccount = organizationAccounts.find((org) => org.id === accountId);

  return {
    onboardingAccount: nullthrows(organizationAccount ?? personalAccount),
  };
}

function getCLIPrefix(apiServerUrl?: string) {
  const prPreviewRegex = /^https:\/\/pr-\d+\.api\.expo\.dev$/;

  if (apiServerUrl === 'https://staging-api.expo.dev' || prPreviewRegex.test(apiServerUrl ?? '')) {
    return 'EXPO_STAGING';
  }

  if (apiServerUrl === 'http://localhost:3000') {
    return 'EXPO_LOCAL';
  }

  return null;
}

export function getEnvironmentCorrectEASCommand(command: string) {
  const cliPrefix = getCLIPrefix(process.env.API_SERVER_INTERNAL_URL);

  return `$ ${cliPrefix ? `${cliPrefix}=1 ` : ''}${command}`;
}

export function useTrackOnboardingEvent(event: OnboardingEvents) {
  const pageProps = usePageProps();
  const router = useRouter();
  const { accountId } = formatParams(router?.query);

  const hasTrackedEvent = useRef(false);
  useEffect(
    function trackOnboardingProgress() {
      if (pageProps.currentUser && !hasTrackedEvent.current) {
        track(event, {
          currentURL: window.location.href,
          currentUserId: pageProps.currentUser?.id,
          accountId,
          accountName: getPrimaryOnboardingAccount(pageProps.currentUser, accountId)
            .onboardingAccount.name,
        });
        hasTrackedEvent.current = true;
      }
    },
    [pageProps.currentUser]
  );
}
